import * as React from "react"

function AccessRefactions(props) {
  return (
    <svg viewBox="0 0 70 70" {...props}>
      <path d="M55 6l-9 9 9 9 9-9s1.8 1.8 0 7.2-3.6 7.2-6.3 9.9c-4.5 4.5-9.9-2.7-13.5.9-1.4 1.4-23.4 25.2-27 28.8-5.4 5.4-9 1.8-9.9.9-.9-.9-4.5-4.5.9-9.9 3.6-3.6 26.7-24.9 28.8-27 3.6-3.6-3.6-9 .9-13.5 2.7-2.7 4.5-4.5 9.9-6.3C53.2 4.2 55 6 55 6z" />
      <path d="M27.6 31.4L12.2 16h-1.9L5.5 9.3l3.8-3.8 6.7 4.8v1.9l15.5 15.5c-1.2 1.2-2.5 2.4-3.9 3.7zM63 63c-.9.9-4.3 4.3-10-1.5-4.4-4.3-12-11.9-16.3-16.2.9-1 1.8-1.9 2.7-2.9 2.1-2.3 4.1-4.4 5.6-6L61.6 53c5.6 5.7 2.2 9.1 1.4 10z" />
    </svg>
  )
}

export default AccessRefactions
