import { useStaticQuery, graphql } from "gatsby"
export const usePrismBanner = () => {
const prismic = useStaticQuery(
    graphql`
        query {
            allPrismicHomepage {
                edges {
                node {
                    data {
                    body2 {
                        __typename
                        ... on PrismicHomepageBody2Banner {
                            items {
                                banner_image_xlg {
                                    url
                                }
                                banner_image_lg {
                                    url
                                }
                                banner_image_md {
                                    url
                                }
                                banner_image_xs {
                                    url
                                }
                                # banner_title {
                                #     html
                                # }
                                banner_subtitle {
                                    html
                                }
                                banner_description {
                                    text
                                }
                                initial_degraded
                                final_degraded
                                banner_link_title {
                                    text
                                }
                                banner_url
                                # model_icon
                            }
                        }
                    }
                    }
                }
                }
            }
        }
    `
    );
    


return prismic.allPrismicHomepage
        
}
