import React from 'react'
import Slider from 'react-slick';
import { Link } from 'gatsby';
import {graphql, useStaticQuery} from 'gatsby'

import AccessRefactions from '../../../components/svg/access/access_refactions'
import AccessDealers from '../../../components/svg/access/access_dealers'
import AccessWarranty from '../../../components/svg/access/access_warranty'

import { useCurrenDevice } from '../../../hooks/useCurrentDevice';

function Access() {

    const query = useStaticQuery(graphql`
        query {
            allPrismicHomepage {
            edges {
                node {
                data {
                    body{
                    items{
                        access_url
                        access_title{
                        text
                        }
                        access_description{
                        text
                        html
                        }
                    }
                    }
                }
                }
            }
            }
        }
    `)

    const accessQuery = query.allPrismicHomepage.edges[0].node.data.body[0]
    const [device] = useCurrenDevice();
    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <div className="slick-button-prev"/>,
        nextArrow: <div className="slick-button-next" />
    };
    
    function get_access_maintenance(){
        return(
            <Link className="access__item access_maintenance_item" to={accessQuery.items[0].access_url}>
                <div className="access__icon_container">
                    <div className="access__icon">
                        <AccessRefactions></AccessRefactions>
                    </div>
                </div>
                <div className="access__content">
                    <h1 className='access__title'>{accessQuery.items[0].access_title.text}</h1>
                    <p className='access__description'> {accessQuery.items[0].access_description.text} </p>
                    
                </div>
            </Link>
        )
    }
    function get_access_warranty(){
        return(
            <Link className="access__item access_warranty_item" to={accessQuery.items[1].access_url}>
                <div className="access__icon_container">
                    <div className="access__icon">
                        <AccessWarranty></AccessWarranty>
                    </div>
                </div>
                <div className="access__content">
                    <h1 className='access__title'>{accessQuery.items[1].access_title.text}</h1>
                    <p className='access__description'> {accessQuery.items[1].access_description.text}</p>                                
                </div>
            </Link>
        )
    }
    function get_access_dealers(){
        return(
            <Link className="access__item access_dealers_item" to={accessQuery.items[2].access_url}>
                <div className="access__icon_container">
                    <div className="access__icon">
                        <AccessDealers></AccessDealers>
                    </div>
                </div>
                <div className="access__content">
                    <h1 className='access__title'>{accessQuery.items[2].access_title.text}</h1>
                    <p className='access__description'> {accessQuery.items[2].access_description.text}</p>                                
                </div>
            </Link>
        )
    }
    return (
        <div className="access__container ">

            {
                device === 'mobile' ?
                    <Slider {...settings}>
                        {get_access_maintenance()}
                        {get_access_warranty()}
                        {get_access_dealers()}
                    </Slider>
                    :
                    <>
                        {get_access_maintenance()}
                        {get_access_warranty()}
                        {get_access_dealers()}
                    </>
            }
        </div>
    )
}

export default Access
