import React from 'react'

import Access from '../../content/home/footer/access';
import { Picture } from 'react-responsive-picture';
import PhoneRingning  from '../svg/commons/phone_ringing';
import DetailLogo from '../../content/model_detail/detail_logo';

export const AccessSection=()=>{
    

    function get_call_section(){
        return (
            <div className="detail_maintenance_header_telcontainer">
                <div className="detail_maintenance_header_telicon">
                    <PhoneRingning></PhoneRingning>
                </div>
                <div className="detail_maintenance_header_info_container">
                    <div className="detail_maintenance_header_info_llamanos">Llámanos:</div>
                    <div className="detail_maintenance_header_info_tel">800 CALOREX (225 67 39) </div>
                </div>
            </div>
        )
    }
    return(
        <div className="access-section">
            <div className="detail_maintenance_header">
                <div className="detail_maintenance_header_right">
                    <DetailLogo _name='' _title="Servicio de Mantenimiento"></DetailLogo>
                    <p className="detail_maintenance_header_description">¿Necesitas un técnico especializado que revise tu Calorex?</p>
                    {get_call_section()}
                </div>
                <div className="detail_maintenance_header_left">
                    <div className="detail_maintenance_header_imagecontainer">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/maintenance/calorex_maintenance_xs.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/maintenance/calorex_maintenance_lg.png',
                                media: "(max-width: 1920px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/maintenance/calorex_maintenance_xlg.png',
                                media: "(min-width: 1920px)",
                            }
                            ]}
                        />
                    </div>
                    {get_call_section()}
                </div>
            </div>
            <div className="detail_maintenance_body">
                <div className="detail_maintenance_body_items">
                    <Access _section='maintenance'></Access>
                </div>
                
            </div>
        </div>
    )
}
