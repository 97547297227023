import React from 'react'
// import {graphql, useStaticQuery} from 'gatsby'

const Storytelling = (props) => {
        
    const storyQuery = props.data.primary;
    return (
        <div className="storytelling__container">
            <div className="storytelling__text" dangerouslySetInnerHTML={{__html: storyQuery.story_telling.html}}> 
            </div>
        </div>
    )
}

export default Storytelling
