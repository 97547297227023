import React from 'react'

const DesignIcon = props => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 139.2 130"
    xmlSpace="preserve"
    {...props}
  >
    
    <path      
      d="M57.2 35.5c-2 0-3.7.4-3.7 1.8v13.4h7.4V37.3c0-1.5-1.7-1.8-3.7-1.8m24.8 0c-2 0-3.7.4-3.7 1.8v13.4h7.4V37.3c0-1.5-1.6-1.8-3.7-1.8m-13.6-5.4c-.9 0-1.6.7-1.6 1.6v18.9h5.8V31.8c0-.9-.7-1.6-1.6-1.6h-2.6z"
    />
    <path      
      d="M139.2 68c-.4-17.9-7.7-34.8-20.4-47.5C106 7.7 89.2.4 71.3 0h-1.7C51.3 0 34.1 7 21 19.8 7.4 33 0 50.7 0 69.6c0 20.7 10.1 41.4 25.6 52.7 7 5.1 14.5 7.7 21.7 7.7 4.2 0 8.2-.9 11.7-2.7 9.2-4.7 14.7-14.8 15.3-27.8h1c2 0 3.6-1.5 3.7-3.5l.3-3.9c.2-3 1.8-5.7 4.1-7.6C88.1 80.7 91 75.2 91 69v-9.6c.8-.4 1.3-1.2 1.3-2.1V54c0-1.3-1-2.3-2.3-2.3H49.2c-1.3 0-2.3 1-2.3 2.3v3.3c0 .9.5 1.7 1.3 2.1V69c0 6.2 2.9 11.7 7.5 15.4 2.3 1.9 3.9 4.6 4.1 7.6l.3 3.9c.1 2 1.8 3.5 3.7 3.5h.8c-.5 9.2-4.1 16.2-10 19.2-2.2 1.1-4.7 1.7-7.4 1.7-5.2 0-10.7-2-16.1-5.9-13-9.4-21.5-27.1-21.5-44.8 0-16.3 6.4-31.5 18.1-42.9C39 15.7 53.9 9.6 69.6 9.6H71c32.1.8 57.8 26.5 58.5 58.6.3 12.9-3.7 25.8-11.2 36.3-1.5 2.1-1.1 4.9.9 6.5.9.7 1.9 1.1 3 1.1 1.5 0 3-.7 3.8-2C134.9 98 139.5 83 139.2 68m-76.4 5l3.8-13.4h6.5l-2.7 8 6.9-.2-11 17.1 2.3-11.8-5.8.3z"
    />
    {props.section!==undefined ?
    <linearGradient id={`${props.section}_design_icon`}>
      <stop offset="5%" stopColor={props.fillstart} />
      <stop offset="95%" stopColor={props.fillend} />
    </linearGradient>
      : null 
    }
  </svg>
)

export default DesignIcon