import React from 'react'

function Iframe({_src,_width,_height}) {
    const _title = ""
    return (
        <>
            <iframe src={_src} width={_width} height={_height} title={_title} ></iframe>
        </>
    )
}

export default Iframe
