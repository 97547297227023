import React from "react";
import { Link } from 'gatsby';
import SvgIconShop from '../../components/svg/shop_online/shop_online_icon';
import stylesShop from '../../styles/_components/_buttons/_button_shop.module.scss';

const ButtonShop = () => {
    return(
        <div className={`${stylesShop.sButton_container}`}>
            <Link to='/compra-en-linea' className={`${stylesShop.sShop_button} tag-compra-en-linea`}>
                <div className={`${stylesShop.sSvg_container}`}>
                    <SvgIconShop className={stylesShop.sShop_icon} />
                </div>
                <p className={`${stylesShop.sButton_text}`}>¡Compra en línea!</p>
            </Link>
        </div>
    )
}
export default ButtonShop