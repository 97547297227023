import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import HomeBanner from "../content/home/banner/home_banner"
import HomeHelp from "../content/home/help/home_help"
import Storytelling from "../content/home/storytelling/storytelling"
import HomeBannerAlert from "../content/home/new_banner/_home_banner_alert"
import HomeProducts from "../content/home/products/home_products"
import { AccessSection } from "../components/_access/_access_section"
import HomeNews from "../content/home/news/home_news"
// import LeedSection from "../content/home/leed/_home_leed"
import HomeCampaign from "../content/home/campaign/_campaign"
import HomeArchitecture from "../content/home/architecture/_architecture"
import HomeCertificates from "../content/home/home_certificates/home_certificates"
import ButtonShop from "../components/buttons/_button_shop"
// import { Alert } from "../components/_alerts/_alert"

import GetBodyItem from "../components/_helpers/_get_body_item"

const Index = () => {
  const query = useStaticQuery(graphql`
    query {
      allPrismicHomepage {
        edges {
          node {
            data {
              body1 {
                __typename
                ... on PrismicHomepageBody1Metas {
                  slice_type
                  primary {
                    title {
                      text
                    }
                    description {
                      text
                    }
                    image_share {
                      url
                    }
                  }
                }
                __typename
                ... on PrismicHomepageBody1Storytelling {
                  primary {
                    story_telling {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let data = query.allPrismicHomepage.edges[0].node
  let body_info = new GetBodyItem()
  let storytelling_data = body_info.get_index_item(
    data,
    "PrismicHomepageBody1Storytelling"
  )
  // const [alertOpen, setAlertOpen] = useState(true)
  
  return (
    <Layout>
      {<ButtonShop />}
      {/* {
        alertOpen &&
          <Alert onClickButton={setAlertOpen}/>
      } */}
      <div className="home-bg-gradient"></div>
      <HomeBanner />
      <HomeHelp />
      <HomeProducts />
      <Storytelling data={storytelling_data} />
      <HomeBannerAlert />
      <HomeCampaign />
      {/* <LeedSection /> */}
      <HomeArchitecture />
      <HomeNews />
      <div className="group-gradient">
        <AccessSection />
        <HomeCertificates />
      </div>
    </Layout>
  )
}

export default Index
