import React,{useRef,useState, useEffect} from 'react'

import { useCurrenDevice } from "../../../hooks/useCurrentDevice";
import Loadable from "@loadable/component"
import  {Link} from 'gatsby';

import { Picture } from 'react-responsive-picture';
//import  CssClassManager from '../../../components/_helpers/_css_class_manager'

const AwesomeSwiper = Loadable(() => import("react-awesome-swiper"));

function ProdutcsCarousel( props ) {
    const swiperProduct = useRef(null)
   // const wrapperProducts = useRef(null)
    const [windowWidth, setWindow] = useState(null)
    const [filter, setFilter] = useState(props._product)
    const [items, setItems] = useState([])
    const [widthItems, setWidthItems] = useState()
    const [device] = useCurrenDevice();
  //  const cssClassManager = new CssClassManager()
    let arrayCalentador = []

    useEffect(() => {
      window.addEventListener("resize", windowSize);
      return () => {
          window.removeEventListener("resize", windowSize);
      };
  }, )

  function windowSize(){
    setWindow(window.innerWidth);
  }

    useEffect(() => {
      setFilter(props._product)
    }, [props._product])

    useEffect(() => {
      if(windowWidth >= 1024){
        setWidthItems(4)
      }
      if(windowWidth >= 768 && windowWidth < 1024 ){
        setWidthItems(3)
      }
      if(windowWidth < 768 ){
        setWidthItems(1)
      }
    }, [windowWidth])

    useEffect(() => {
      props.calentadoresQuery.edges.map(calentador => {
        if(calentador.node.data.category.uid === filter){
          return  arrayCalentador.push(calentador)
        }else return null
      })
      
      setItems(arrayCalentador)
      // console.log('filter', filter)
      // eslint-disable-next-line  
    }, [filter])

    const configMobile = {
        // loop : true,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        speed: 500,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          bulletElement : 'li',
        //   hideOnClick :true,
          clickable :true,
        },
        on: {
          slideChange: function () {
            // console.log(this.activeIndex);
          },
        },
      };
    const configDesktop = {
        // loop : true,
        // centeredSlides: true,
        slidesPerView: 4,
        spaceBetween: 10,
        preloadImages: false,
        lazy: true,
        speed: 500,
        breakpoints:{
          1023:{
            slidesPerView: 3
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: function () {
            // console.log(this.activeIndex);
          },
        },
      };

   // let index = 1;

    function getSwiper() {
      
      let class_center = '';
      switch(items.length)
      {
        case 2: class_center = 'wrapperProducts--pair';
        break;
        case 3: class_center = 'wrapperProducts--three';
        break;
        default: 
        break
      }
      return <AwesomeSwiper ref={swiperProduct} config={device === "mobile" ? configMobile : configDesktop}>
            <div className="swiper-pagination products__pagination"></div>
            <div className={`swiper-wrapper products__wrapper ${class_center}`}>
            {
                items.map(calentador => {
                  return(
                          <Link to={`/calentadores/${calentador.node.uid}`} className="swiper-slide product__slide" key={calentador.node.uid}>
                              <div className="products__detail--container">
                                <div className="product__image">
                                  <Picture
                                      sources = {[
                                      {
                                          srcSet: calentador.node.data.menu_image.mb.url,
                                          media: "(max-width: 767px)",
                                      },
                                      {
                                          srcSet: calentador.node.data.menu_image.lg.url,
                                          media: "(max-width: 1919px)",
                                      },
                                      {
                                          srcSet: calentador.node.data.menu_image.url,
                                          media: "(min-width: 1919px)",
                                      }
                                      ]}
                                  />
                                </div>
                                <div className="product__description">
                                    <div className="product__name">
                                        <span> {calentador.node.data.model_name.text} </span>
                                    </div>
                                    <div className="product__claim">
                                        <span> {calentador.node.data.claim.text} </span>
                                    </div>
                                </div>
                              </div>
                          </Link>
                        )
                    })
            }
            </div>
            <div className="swiper-button-prev product__prev"></div>
            <div className="swiper-button-next product__next"></div>
      </AwesomeSwiper>
    }
    
    function getItem() {
      return <div className="product__single--slide" >
        {
          items.map(calentador => {
            return(
              <div className="single-product" key={calentador.node.uid}>
                  <Link to={`/calentadores/${calentador.node.uid}`} className="products__detail--container">
                      <div className="product__image">
                        <Picture
                          sources = {[
                            {
                              srcSet: calentador.node.data.menu_image.mb.url,
                              media: "(max-width: 767px)",
                          },
                          {
                              srcSet: calentador.node.data.menu_image.lg.url,
                              media: "(min-width: 768px)",
                          }
                          ]}
                        />
                      </div>
                      <div className="product__description">
                          <div className="product__name">
                              <span> {calentador.node.data.model_name.text} </span>
                          </div>
                          <div className="product__claim">
                              <span> {calentador.node.data.claim.text} </span>
                          </div>
                      </div>
                  </Link>
              </div>
            )
          })
        }
      </div>
    }

    return (
        <>
          {
            items.length > widthItems ? getSwiper() : getItem()
          }
        </>
    )
}

export default ProdutcsCarousel
