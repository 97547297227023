import { useStaticQuery, graphql } from "gatsby"
export const usePrismCalentadores = () => {
  const prismicCalentadores = useStaticQuery(
    graphql`
    {
      allPrismicModel(
        sort: {fields: [data___category___uid, data___order], order: ASC }
    ) {
        edges{
          node{
            uid
            data{
              order
              svg_icon
              menu_icon {
                url
              }
              model_name {
                text,
                html
              }
              title_color_start
              title_color_end
              claim{
                text
              }
              ideal_copy{
                text
              }
              ideal_list{
                text
              }
              main_image_xs{
                url
                alt
              }
              main_image_sm{
                url
                alt
              }
              main_image_md{
                url
                alt
              }
              main_image_lg{
                url
                alt
              }
              main_image_xlg{
                url
                alt
              }
              menu_image{
                url
                alt
                lg{
                  url
                }
                tb{
                  url
                }
                mb{
                  url
                }
              }
              category{
                uid
                document{
                  data{
                    name{
                      text
                    }
                  }
                }
              }
              body{
                ... on PrismicModelBodyBannerVideo{
                  primary{
                    banner_image_xlg{
                      alt
                      url
                    }
                    banner_image_sm{
                      alt
                      url
                    }
                    banner_image_xs{
                      alt
                      url
                    }
                  }
                }
                __typename
                ... on PrismicModelBodyVentajas{
                  items{
                    advantage_title{
                      html
                      text
                    }
                    advantage_description{
                      html
                      text
                    }
                    advantage_image{
                      url
                      alt
                    }
                    advantage_icon{
                      url
                      alt
                    }
                  }
                }
                # __typename
                #   ... on PrismicModelBodyLeed{
                    
                #     primary{
                #       leed_active
                #     }
                     
                #   }
                __typename
                  ... on PrismicModelBodyArchivos{
                    primary{
                      technical{
                        url
                      }
                      manual{
                        url
                      }
                    }                      
                  }
                __typename
                ... on PrismicModelBodyBannerApp{
                  primary{
                    app_image{
                      alt
                      url
                      sm{
                        url
                      }
                      md{
                        url
                      }
                    }
                  }
                  items{
                    link_image{
                      url
                      alt
                     

                    }
                    app_link{
                      text
                    }
                  }
                }
                __typename
                ... on PrismicModelBodySistemas{
                  items{
                    systems_title{
                      html
                      text
                    }
                    systems_description{
                      html
                      text
                    }                    
                    systems_image{
                      url
                      alt
                      xs{
                        url                      
                      }
                      sm{
                        url                      
                      }                    
                      lg{
                        url                      
                      }
                    }
                  }
                    
                }
                
                __typename
                ... on PrismicModelBodyConoceloGrupo{
                  items{
                    conocelo_title{
                      text
                      html
                    }
                    conocelo_description{
                      text
                      html
                    }
                    conocelo_image{
                      alt
                      url
                      lg{
                        url
                      }
                      sm{
                        url
                      }
                    }
                    conocelo_x
                    conocelo_y
                  }
                }
                __typename
                ... on PrismicModelBodyStorytelling2{
                  primary{
                    storytelling_2_description{
                      text
                      html
                    }
                  } 
                }
                __typename
                ... on PrismicModelBodyCertificados{
                  primary{
                    certificado_title{
                      text
                      html
                    }
                  }
                  items{
                    certificado_imagen{
                      url
                      alt
                    }
                  }
                }
              }
            }
          
          }
        }
      }
    }
    `
  )
  return prismicCalentadores.allPrismicModel
  
}
