import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { Picture } from 'react-responsive-picture';
import DetailLogo from '../../model_detail/detail_logo'

import GetBodyItem from "../../../components/_helpers/_get_body_item"

const HomeBannerAlert=()=>{
    const query = useStaticQuery(graphql`
    query {
        allPrismicHomepage {
            edges {
              node {
                data {
                  body1 {
                    __typename
                    ... on PrismicHomepageBody1BannerAlerta {
                      id
                      primary {
                        title_alert {
                          text
                          html
                        }
                        subtitle_alert {
                          text
                          html
                        }
                        content_alert {
                          text
                          html
                        }
                        image_alert {
                          sm {
                            url
                            alt
                          }
                          md {
                            url
                            alt
                          }
                          url
                          alt
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    }
  `)

  let data = query.allPrismicHomepage.edges[0].node
  let body_info = new GetBodyItem()
  let alert_data = body_info.get_index_item(data, "PrismicHomepageBody1BannerAlerta")

  const {title_alert, subtitle_alert, content_alert, image_alert} = alert_data.primary

    return(
        <div className="home-BannerAlert">
            <div className="home-BannerAlert__bg">
                <Picture
                    sources = {[
                        {
                            srcSet: image_alert.sm.url,
                            media: "(max-width: 768px)",
                        },
                        {
                            srcSet: image_alert.md.url,
                            media: "(max-width: 1365px)",
                        },
                        {
                            srcSet: image_alert.url,
                            media: "(min-width: 1366px)",
                        },
                    ]}
                />
            </div>
            <div className="home-BannerAlert__stage">
                <DetailLogo _name='' _title=''></DetailLogo>
                <p className="home-BannerAlert__title">{title_alert.text}</p>
                <p className="home-BannerAlert__subtitle">{subtitle_alert.text}</p>
                <div dangerouslySetInnerHTML={{__html: content_alert.html}} className="home-BannerAlert__content" />
            </div>
        </div>
    )
}

export default HomeBannerAlert;

