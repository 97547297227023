import React from 'react'
import { TextGradient } from '../../../components/_text/_text_gradient';

// import CalorexDesign from '../../../components/svg/commons/calorex_design'
import DesignIcon from '../../../components/svg/commons/design_icon'
import {ButtonSemicircle,Button_Semicircle_types} from '../../../components/buttons/_button_semicircle';

import { Picture } from 'react-responsive-picture';

const HomeCampaign= ()=>{
    let color_start = '#ba0c2f';
    let color_end   = '#d90b2d';

    function get_header(_id){
        let section     = 'home_campaign_'+_id;
        return(
            <div className="home-campaign_header">
                <div className="home-campaign_header_logo">
                    <DesignIcon fillstart={color_start} fillend={color_end} section={section} fill={`url(#${section}_design_icon)`}/>
                </div>
                <div className="home-campaign_header_copy logo_calorex_design">
                    <TextGradient _className="home-campaign_title home-campaign-title-logo" _text='Calorex Design' _color_start={color_start} _color_end={color_end}></TextGradient>
                    <div className="home-campaign_line"></div>
                </div>
            </div>
        )
    }
    return (
        <div className="home-campaign">
            <div className="home-campaign_header_container">
                {get_header(1)}
            </div>
            <div className="home-campaign_body">
                <div className="home-campaign_body_left home-campaign_body_item">
                    <div className="home-campaign_imageconatiner">
                    <Picture
                        sources = {[
                            {
                                srcSet: "/assets/images/home/campaign/calorex_banner_home_calorex_design_320.png",
                                media: "(max-width: 767px)",
                            },
                            {
                                srcSet: "/assets/images/home/campaign/calorex_banner_home_calorex_design_1366.png",
                                media: "(max-width: 1919px)",
                            },
                            {
                                srcSet: "/assets/images/home/campaign/calorex_banner_home_calorex_design_1920.png",
                                media: "(min-width: 1920px)",
                            },
                        ]}
                    />
                    </div>
                </div>
                <div className="home-campaign_body_right home-campaign_body_item">
                    {get_header(2)}
                    <TextGradient _className="home-campaign_title" _text='Innovación, tecnología y diseño a un nuevo nivel.' _color_start={color_start} _color_end={color_end}></TextGradient>
                    <p className="home-campaign_description">Confort inteligente a tu medida, sorpréndete de lo bien que conocerá tu ducha ideal.</p>
                    <div className="home-campaign_button_container">
                        <ButtonSemicircle
                            key={`o-button-semicircle--design`}
                            _to={`/calorex-design/`}
                            _slug={`semicircle_design`}
                            _text='Conócelos'
                            _className={`home-campaign_button`}
                            _button_type={Button_Semicircle_types.REGULAR}
                            >
                        </ButtonSemicircle>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HomeCampaign