import * as React from "react"

function AccessDealers(props) {
  return (
    <svg viewBox="0 0 70 70" {...props}>
      <path d="M33.1 5.3C21 5.7 11 14.9 9.8 26.7c-.2 2.4-.1 4.6.3 6.8 0 0 0 .3.2.7.4 1.6.9 3.2 1.6 4.7 2.4 5.6 8 15.1 20.7 25.3.8.6 1.9.6 2.7 0C47.9 54 53.5 44.6 56 38.9c.7-1.5 1.3-3.1 1.6-4.7.1-.5.2-.7.2-.7.3-1.5.4-3 .4-4.5 0-13.4-11.3-24.2-25.1-23.7zm.9 35.5c-6.5 0-11.8-5.2-11.8-11.5 0-6.4 5.3-11.5 11.8-11.5 6.5 0 11.8 5.2 11.8 11.5-.1 6.3-5.3 11.5-11.8 11.5z" />
    </svg>
  )
}

export default AccessDealers
