import React from 'react'
// import { Link } from "gatsby"
import { TextGradient } from '../../../components/_text/_text_gradient'

import { ButtonArrow } from '../../../components/buttons/_btn_arrow'
import { Picture } from 'react-responsive-picture';

function Banner(props) {

  return (
    <>
      {
        props.item !== undefined &&
        <div className="banner__container">
          <div className="banner__image">
            <Picture
              sources={[
                {
                  srcSet: props.item.banner_image_md.url,
                  media: "(max-width: 1023px)",
                },
                {
                  srcSet: props.item.banner_image_lg.url,
                  media: "(max-width: 1366px)",
                },

                {
                  srcSet: props.item.banner_image_xlg.url,
                  media: "(min-width: 1366px)",
                }

              ]}
            />
          </div>
          <div className="banner__header">
            {
              props.item.banner_title &&
              <div className="banner__title" dangerouslySetInnerHTML={{ __html: props.item.banner_title.html }}></div>
            }
            {
              props.item.banner_description &&
              <div className="banner__description">
                <TextGradient
                  _text={props.item.banner_description.text}
                  _deg={180}
                  _className={'banner-gradient'}
                  _color_start={props.item.initial_degraded}
                  _color_end={props.item.final_degraded} />
              </div>
            }
            <div className="banner_subtitle"  >
              {
                props.item.model_icon && <div className={"banner_subtitle_logo"} dangerouslySetInnerHTML={{ __html: props.item.model_icon }} />
              }
              {
                props.item.banner_subtitle && <div className="banner_subtitle_img" dangerouslySetInnerHTML={{ __html: props.item.banner_subtitle.html }} />
              }
            </div>
          </div>
          {props.item.banner_link_title.text && props.item.banner_link_title.text.length > 1 ? <ButtonArrow _className='banner__link--container' _to={props.item.banner_url} _text={props.item.banner_link_title.text} section={props.item.banner_link_title.text} /> : null}
        </div>
      }
    </>
  )
}

export default Banner
