import * as React from "react"

function AccessWarranty(props) {
  return (
    <svg viewBox="0 0 70 70" {...props}>
      <path d="M59.1 12.1c-18.8 0-22-7.2-22-7.3-.1-.4-.5-.6-.9-.6s-.7.2-.9.6c0 .1-3.3 7.3-22 7.3-.5 0-.9.4-.9.9v26.7c0 13.3 22.5 23.4 23.4 23.9.1.1.2.1.4.1.1 0 .3 0 .4-.1C37.6 63.2 60 53 60 39.7V13.1c.1-.5-.4-1-.9-1zM45.8 29.3l-9.2 11.2c-.3.4-.7.5-1.2.5-.3 0-.7-.1-.9-.3L28 35.5c-.6-.5-.7-1.5-.2-2.1.5-.6 1.5-.7 2.1-.2l5.3 4.3 8.3-10.1c.5-.6 1.5-.7 2.1-.2.7.6.8 1.5.2 2.1z" />
    </svg>
  )
}

export default AccessWarranty
