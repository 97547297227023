import React,{useState} from 'react'

import Styles from '../../styles/_components/ui/menuui.module.scss'

const MenuUI=(props)=>{
    let callBack =  props._callback 
    let items = props._items;
    let [selected,setSelected]= useState(0)
      
    function clickHandler(_item,_index){
        
        setSelected(_index);
        callBack(_item);
    }
    
    function getItems(_items){
        let count=-1;
        return _items.map((_item)=>{
            count++
            let class_selected = count === selected ? 'o-menuui__item o-menuui__item__selected': '';
            let _index = count;
            return (
                <div className={`o-menuui__item ${Styles.oMenuui__item} o-menuui__item__${count} ${class_selected}`} onClick={()=>{clickHandler(_item,_index)}} key={`o-menuui__item__${count}`}>
                    <p className={`o-menuui__item__p ${Styles.oMenuui__item__p}`}>{_item.data.title.text}</p>
                </div>
            )
        })
    }
    return(
        <div className={`o-menuui__container ${Styles.oMenuui__container}`}>
            <div className={Styles.oMenuui__items_container}>
                {getItems(items)}
            </div>
        </div>
    )
}

export default MenuUI;