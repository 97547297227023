import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import '../../../styles/pages/home/_home.scss'
import {ButtonSemicircle} from '../../../components/buttons/_button_semicircle';

import CalorexLogo from '../../../components/svg/commons/calorex_logo'

const HomeHelp = () => {

    const helpQuery = useStaticQuery(graphql`
        query{
            prismicHomepage{
                data{
                    help_title{
                        text
                    }
                    help_description{
                        text
                    }
                }
            }
        }
    `)

    
    return (
        <div className="home__help--container">
            <div className="home__help__logocontainer">
                <CalorexLogo fill="#ffffff" fillbody="#ffffff"/>
            </div>
            <div className="help__title">
                <span>{helpQuery.prismicHomepage.data.help_title.text}</span>
            </div>
            <div className="help_desc">
                <span>{helpQuery.prismicHomepage.data.help_description.text}</span>
            </div>
            <ButtonSemicircle _to="/tu-calentador-ideal" _className="help__link" _text="Comenzar"></ButtonSemicircle>
            <div className="home__help_bgtext">Ayuda</div>
            
        </div>
    )
}

export default HomeHelp
