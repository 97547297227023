import React from 'react'
import { Picture } from 'react-responsive-picture';
import DetailLogo from '../../../content/model_detail/detail_logo'

const HomeArchitecture=()=>{

    return(
        <div className="home-architecture">
            <div className="home-architecture__bg">
                <Picture
                    sources = {[
                        {
                            srcSet: "/assets/images/home/architecture/calorex_arquitectura_agua_caliente_768.jpg",
                            media: "(max-width: 768px)",
                        },
                        {
                            srcSet: "/assets/images/home/architecture/calorex_arquitectura_agua_caliente_1366.jpg",
                            media: "(max-width: 1365px)",
                        },
                        {
                            srcSet: "/assets/images/home/architecture/calorex_arquitectura_agua_caliente_1920.jpg",
                            media: "(min-width: 1366px)",
                        },
                    ]}
                />
            </div>
            <div className="home-architecture__stage">
                <DetailLogo _name='' _title=''></DetailLogo>
                <p className="home-architecture__title">Arquitectura de agua caliente.</p>
                <p className="home-architecture__subtitle">Asesoría, capacitación, supervisión y servicio post venta.</p>
                <p className="home-architecture__content">Todos tus proyectos de construcción están protegidos por la red de expertos en calentadores más importante de México.</p>
            </div>
        </div>
    )
}

export default HomeArchitecture;

