import React from 'react'
import {usePrismBlogs} from '../../../hooks/use_prism-blogs'
import { BlogItem,BlogItemTypes  } from '../../../components/_blog/_blog_item';

function HomeNews() {
    const blogs= usePrismBlogs();
    // console.log('blogs',blogs);
    // console.log('lenght', blogs.edges.length-1);
    // let last = blogs.edges.length-1;
    return (
        <>
             <div className="home__news" >
                <BlogItem _className='news__item' blog={blogs.edges[0]} type={BlogItemTypes.TO_RIGHT}></BlogItem>
                <BlogItem _className='news__item' blog={blogs.edges[1]} type={BlogItemTypes.TO_LEFT}></BlogItem>
            </div>
        </>
    )
}

export default HomeNews
